<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'hospitals' })"
    >
      {{ $t('veterinary.hospitals') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">البيانات الشخصية</h5>
              </template>
              <b-row>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="الاسم بالكامل"
                    label="الاسم بالكامل"
                    v-model="doctorData.clinic_name"
                    id="clinic_name"
                    name="clinic name"
                    validate="required"
                    :disabled="$route.name === 'showHospital'"
                  ></input-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="عنوان العيادة"
                    label="عنوان العيادة"
                    v-model="doctorData.clinic_address"
                    name="clinic address"
                    validate="required"
                    :disabled="$route.name === 'showHospital'"
                  ></input-form>
                </b-col>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="البريد الالكترونى"
                    label="البريد الالكترونى"
                    v-model="doctorData.email"
                    name="email"
                    validate="required"
                    :disabled="$route.name === 'showHospital'"
                  ></input-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="رقم الهاتف"
                    label="رقم الهاتف"
                    v-model="doctorData.primary_phone_number"
                    name="primary_phone_number"
                    validate="required|numeric"
                    :disabled="$route.name === 'showHospital'"
                  ></input-form>
                </b-col>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="رقم الهاتف الثانوى"
                    label="رقم الهاتف الثانوى"
                    v-model="doctorData.secondary_phone_number"
                    name="secondary_phone_number"
                    validate="numeric"
                    :disabled="$route.name === 'showHospital'"
                  ></input-form>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">الصور</h5>
              </template>
              <div v-if="$route.name === 'editHospital'">
                <div v-for="(item, index) in doctorData.uploaded_documents" :key="item.doc.id" class="mb-4">
                  <cropper-images
                    :label="item.doc.title"
                    @cropper-save="(file) => saveMasterImage(file, index)"
                    :progressLoading="loadingLogo"
                    :showProgress="false"
                    :multi="false"
                    :imageUrl="item.url"
                  />
                </div>
              </div>
              <div v-else>
                <div v-for="item in doctorData.uploaded_documents" :key="item.id" class="mb-4">
                  <div>
                    <span>{{ item.doc.title }}</span>
                    <!-- <hr /> -->
                    <div class="d-flex gap-3 flex-wrap justify-content-between mb-4 mt-2" style="gap: 10px;">
                      <img :src="item.url" class="rounded-circle object-fit-cover" style="width: 80px; height: 40px;"/>
                      <b-button variant="outline-primary" id="show-btn" @click="showModal(item)">عرض الصورة</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>

            <b-card
              class="mb-4"
              v-if="$route.name === 'showHospital' && doctorData.prices"
            >
              <template v-slot:header>
                <h5 class="text-primary">الاسعار</h5>
              </template>
              <b-row>
                <b-col sm="12">
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                  >
                    <p class="setting-box-title">كشف العيادة</p>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="doctorData.prices.clinic_examine"
                        :disabled="$route.name === 'showHospital'"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                  >
                    <p class="setting-box-title">زيارة منزلية</p>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="doctorData.prices.outdoor_examine"
                        :disabled="$route.name === 'showHospital'"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                  >
                    <p class="setting-box-title">استشارة</p>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="doctorData.prices.consulting"
                        :disabled="$route.name === 'showHospital'"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-card v-if="$route.name === 'showHospital'">
              <template v-slot:header>
                <h5 class="text-primary">مواعيد العمل</h5>
              </template>
              <div class="">
                <div class="">
                  <div
                    class="d-flex align-items-center gap_1 time-of-work-item flex-wrap"
                    v-for="(timeOfWork, index) in doctorData.work_times"
                    :key="index"
                  >
                    <div
                      class="time-of-work-item-controls d-flex align-items-center gap_1"
                    >
                      <span
                        class="time-of-work-item-controls-label font-size-20"
                        >{{ timeOfWork.day }}</span
                      >

                      <b-form-checkbox
                        :id="`time-of-work-item-controls-checkbox-${index}`"
                        v-model="timeOfWork.is_active"
                        :name="`time-of-work-item-controls-checkbox-${index}`"
                        class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                        :value="false"
                        :unchecked-value="true"
                        disabled
                        ariaDescribedby="item-checkbox-day"
                      >
                        مغلق
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="timeOfWork.is_active"
                      class="d-flex flex-column gap_1"
                    >
                      <div
                        class="d-flex align-items-center gap_4 flex-wrap"
                        v-for="(timeItem, idx) in timeOfWork.working_periods"
                        :key="idx"
                      >
                        <div class="d-flex align-items-center gap_1">
                          <h6
                            class="setting-box-title time-of-work-item-label font-size-20"
                          >
                            من
                          </h6>
                          <flat-pickr
                            v-model="timeItem.from"
                            :config="config"
                            class="form-control time-of-work-item-controls-date"
                            placeholder="Select time"
                            name="time-of-work-item-controls-date"
                            disabled
                          />
                        </div>
                        <div class="d-flex align-items-center gap_1">
                          <h6
                            class="setting-box-title time-of-work-item-label font-size-20"
                          >
                            الى
                          </h6>
                          <flat-pickr
                            v-model="timeItem.to"
                            :config="config"
                            class="form-control time-of-work-item-controls-date"
                            placeholder="Select time"
                            name="time-of-work-item-controls-date-to"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="$route.name !== 'showHospital'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >تعديل</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
            <b-spinner small type="grow"></b-spinner>
            Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <b-modal ref="modal-photo" id="modal-photo" centered hide-footer hide-header-close>
      <template #modal-title>
        {{ selectedPhoto.title }}
      </template>
      <div class="image-container d-block text-center">
        <img :src="selectedPhoto.url" class="img-fluid" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../../services/index'
import commonMixin from '@/mixins/commonMixin'

export default {
  components: {},
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      selectedPhoto: {},
      loadingLogo: 0,
      loading: false,
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: false,
        defaultDate: '14:30'
      },
      loadingSubmit: false,
      doctorServiceSelected: [{ allow_clinic_examine: true }],
      doctorData: {
        clinic_name: '',
        clinic_address: '',
        email: '',
        primary_phone_number: '',
        secondary_phone_number: '',
        logo: '',
        prices: {
          consulting: '',
          outdoor_examine: '',
          clinic_examine: ''
        }
      }
    }
  },
  methods: {
    getdoctorData () {
      api.getClinic(this.$route.params.id).then((res) => {
        this.doctorData = res.data
      })
    },
    showModal (item) {
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.title }
    },
    onSubmit () {
      if (this.$route.name === 'editHospital') {
        this.editHospital()
      } else {
        console.log('test')
      }
    },
    saveMasterImage (file, index) {
      console.log(file, index)
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options)
        .then((res) => {
          this.doctorData.uploaded_documents[index].url = res.data.url
          this.showSuccessUploadFile()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editHospital () {
      this.loadingSubmit = true
      api
        .editClinic(this.$route.params.id, {
          clinic_name: this.doctorData.clinic_name,
          clinic_address: this.doctorData.clinic_address,
          email: this.doctorData.email,
          primary_phone_number: this.doctorData.primary_phone_number,
          secondary_phone_number: this.doctorData.secondary_phone_number,
          logo: this.doctorData.logo,
          uploaded_documents: this.doctorData.uploaded_documents
        })
        .then(() => {
          this.loadingSubmit = false
          core.showSnackbar('success', 'تم تعديل بنجاح')
          this.$router.push({ name: 'hospitals' })
        })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getdoctorData()
    }
  }
}
</script>
<style scoped>
.setting-box-title {
  min-width: 140px;
}
.setting-box-inner {
  margin-bottom: 30px;
}
</style>
